import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Bottom = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <footer style={styles.bottom}>
      <div style={styles.container}>
        <div style={styles.leftContent}>
          <p style={styles.title}>Contact Us</p>
          <div style={styles.buttonsContainer}>
            <Link to="/bookapt">
              <button
                style={{
                  ...styles.button,
                  backgroundColor: isHovered ? 'gray' : 'white',
                  color: isHovered ? 'white' : 'rgb(64, 64, 64)',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                Schedule an Appointment
              </button>
            </Link>
          </div>
          <p style={styles.text}>Phone: (813) 269-2920</p>
          <p style={styles.text}>Fax: (813) 269-2921</p>
          <p style={styles.text}>office@tampabaymentalhealth.net</p>
        </div>
        <div style={styles.mapContainer}>
          <div style={styles.grayBlock}>
            <iframe
              title="Google Map"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.0632798764095!2d-82.4958992240957!3d28.025735575993755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c13f1349a41b%3A0xc4ffd4581c9818a9!2s3105%20W%20Waters%20Ave%2C%20Tampa%2C%20FL%2033614!5e0!3m2!1sen!2sus!4v1712007868312!5m2!1sen!2sus"              allowFullScreen
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  bottom: {
    backgroundImage: 'linear-gradient(to bottom, rgb(235, 35, 36), rgb(64, 64, 64))',
    color: '#fff',
    textAlign: 'center',
    padding: '20px 0',
    width: '100%',
  },
  container: {
    maxWidth: '900px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    flexWrap: 'wrap', 
  },
  leftContent: {
    paddingRight: '20px',
    flex: '1 1 50%',
    marginBottom: '20px', 
  },
  mapContainer: {
    width: '100%', 
    minHeight: '350px', 
  },
  title: {
    fontSize: '32px',
    marginBottom: '15px',
    fontFamily: 'Montserrat, sans-serif',
  },
  text: {
    fontSize: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    marginBottom: '5px',
  },
  grayBlock: {
    position: 'relative',
    height: '100%', 
    backgroundColor: 'gray',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: 'white',
    color: 'rgb(64, 64, 64)',
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Montserrat, sans-serif',
    transition: 'background-color 0.3s, color 0.3s', 
  },
};

export default Bottom;
