import React from 'react';
import Bottom from './bottom';

import BlueGradientImage from './images/New_Office.jpg'; 

import { Link } from "react-router-dom"; 
import './bookapt.css';

const Bookapt = () => {

  return (
    <div>
      <div className='container1'> 
        <div className='upc1'>
          <div className='gradient'>
            <img src={BlueGradientImage} alt="Blue Gradient" className="gradient-image" />
          </div>
          <div className='profile-down1'>
            
            <div className='profile-title1'>Waters Office</div>
            <div className='profile-description1'>
Psychiatry Services, Psychology Services, TMS Brain Mapping, Group Therapy            </div>
            
          </div>
          <Link to="/location2" className="profbutton"> {}
              Schedule Now
          </Link>
          <div className='mapContainer'>
            <iframe
              title="Google Map"
              width="100%"
              height="300%"
              frameBorder="0"
              styleBorder="0"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.0632798764095!2d-82.4958992240957!3d28.025735575993755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c13f1349a41b%3A0xc4ffd4581c9818a9!2s3105%20W%20Waters%20Ave%2C%20Tampa%2C%20FL%2033614!5e0!3m2!1sen!2sus!4v1712007868312!5m2!1sen!2sus"              allowFullScreen
            ></iframe>
          </div>
        </div>
        
            
          
            
          
      </div>
    </div>
  );
};

export default Bookapt;
