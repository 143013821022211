import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import "./main.css";
import logoImage from "./images/newLogo.jpeg";

function Navbar() {
  const navRef = useRef();
  const [activeLink, setActiveLink] = useState(""); // State to track active link
  const location = useLocation(); // Get current path
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to manage dropdown visibility

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const handleLinkClick = (link) => {
    setActiveLink(link); // Set the active link
    showNavbar();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    setActiveLink(location.pathname); // Update active link on location change
  }, [location.pathname]);

  return (
    <header>
      
      <nav ref={navRef}>
      <div className="dropbuttons">
        <Link to="/" className={`logo ${activeLink === "/" ? "active" : ""}`} onClick={() => handleLinkClick("/")}>
          <img src={logoImage} alt="Logo" style={{ width: "110px", height: "auto", paddingTop: "0px" }} />
        </Link>
        </div>

        <div className="dropbuttons1">
          <Link to="/" className={`book-appointment-btn ${activeLink === "/" ? "active" : ""}`} onClick={() => handleLinkClick("/")}>
            Home
          </Link>
        </div>

        <div className="dropbuttons1">
          <Link to="/bookapt" className={`book-appointment-btn ${activeLink === "/bookapt" ? "active" : ""}`} onClick={() => handleLinkClick("/bookapt")}>
            Appointments
          </Link>
        </div>

        <div className="dropbuttons1">
          <Link to="/checkinsurance" className={`check-insurance ${activeLink === "/checkinsurance" ? "active" : ""}`} onClick={() => handleLinkClick("/checkinsurance")}>
            Insurance
          </Link>
        </div>

        <div className="dropbuttons1 dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <button className="dropbtn">
            Treatments
            <i className="fa fa-caret-down"></i>
          </button>
          <div className={`dropdown-content ${dropdownVisible ? "show" : ""}`}>
            <Link to="/treatment1" className={`Treatments ${activeLink === "/treatment1" ? "active" : ""}`} onClick={() => handleLinkClick("/treatment1")}>
              TMS Brain Mapping
            </Link>
            <Link to="/treatment2" className={`Treatments ${activeLink === "/treatment2" ? "active" : ""}`} onClick={() => handleLinkClick("/treatment2")}>
              Adult Psychiatry
            </Link>
            <Link to="/treatment4" className={`Treatments ${activeLink === "/treatment4" ? "active" : ""}`} onClick={() => handleLinkClick("/treatment4")}>
              Group Therapy
            </Link>
          </div>
        </div>

        <a href="tel:8132692920" className="phone-number-btn">Call Us: (813) 269-2920</a>
        {/*<a href="mailto:office@tampabaymentalhealth.net" className="email-btn">Email Us</a>*/}
        <a href="https://pay.tampabaymentalhealth.net/" className="email-btn" target="_blank" rel="noopener noreferrer">
  Payments
</a>

        
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
