import React, { useState } from 'react';
import './team.css';
import Particlebg from './Particles';

const TeamSection = () => {
    const styles = {
        title: {
            fontSize: '48px',
            marginBottom: '10px',
            color: 'rgb(235, 35, 36)',
            fontFamily: 'ubuntu, sans-serif',
            marginTop: '0',
        },
        subtitle: {
            fontSize: '24px',
            fontFamily: 'ubuntu, sans-serif',
            color: '#000',
            marginBottom: '28px',
        },
        textBox: {
            backgroundColor: 'rgba(255, 255, 255, 1)', // Semi-transparent white background
            padding: '20px',
            borderRadius: '10px', // Rounded corners
            textAlign: 'center',  // Center-align text
            maxWidth: '600px',  // Optional: limit the width of the text box
            margin: '0 auto 30px',  // Center the box and add bottom margin
        },
        // Media queries for mobile responsiveness
        '@media (max-width: 600px)': {
            title: {
                fontSize: '26px',  // Smaller font size for mobile
                color: 'rgb(235, 35, 36)',
            },
            subtitle: {
                fontSize: '15px',  // Smaller subtitle size for mobile
            },
        },
    };

    const disableLinks = true;

    return (
        <div className="tee">
            {/* Background box around the title and subtitle */}
            <div style={styles.textBox}>
                <p style={window.innerWidth <= 600 ? styles['@media (max-width: 600px)'].title : styles.title}>
                    Meet the Team
                </p>
                <p style={window.innerWidth <= 600 ? styles['@media (max-width: 600px)'].subtitle : styles.subtitle}>
                    Click to learn more about our team of expert care providers
                </p>
            </div>
            <div className="card_Container">
                {disableLinks ? (
                    <>
                        <div className="wrapper">
                            <TeamMember
                                name="Stefanny Molina Mesa, APRN"
                                role="Stefanny Molina Mesa is a skilled Nurse Practitioner who completed her Master's Degree at Miami Regional University in 2020. She's a pillar in the community, focused on delivering personalized healthcare and improving patient quality of life."
                                imageSrc="./stef.png"
                                backContent=""
                            />
                        </div>
                        <div className="wrapper">
                            <TeamMember
                                name="Dr. Orlando L. Ruano, DO"
                                role="Dr. Orlando Ruano, DO is an Osteopathic Physician in Tampa, FL and has over 26 years of experience in the medical field. He graduated from Nova Southeastern University Dr. Kiran C. Patel College of Allopathic Medicine in 1997."
                                imageSrc="./ruano.png"
                                backContent=""
                            />
                        </div>
                        <div className="wrapper">
                            <TeamMember
                                name="Yanet Fernandez Diaz, APRN"
                                role="Yanet Fernandez Diaz is an experienced APRN who earned her Master's Degree in Nurse Practitioner studies from Miami Regional University in 2021. She is dedicated to providing compassionate, patient-centered care, leveraging her expertise to support the health of the Tampa Bay community."
                                imageSrc="./YY.png"
                                backContent=""
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {/* Add Links if required */}
                    </>
                )}
            </div>
        </div>
    );
};

const TeamMember = ({ name, role, imageSrc, backContent }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [showRole, setShowRole] = useState(false);

    const handleClick = () => {
        if (window.innerWidth < 758) { // Adjust the width based on your responsive design
            setShowRole(!showRole); // Toggle showing role instead of flipping
        } else {
            setIsFlipped(!isFlipped); // Toggle the flip state for larger screens
        }
    };

    return (
        <div className="team-member-card" onClick={handleClick}>
            {showRole ? (
                // Display name and role on small screens when showRole is true
                <div className="team-member-role">
                    <h3>{name}</h3>
                    <p>{role}</p>
                </div>
            ) : (
                // Show the image or the flipped card based on screen size and state
                <div className={`card1 ${isFlipped ? 'flipped' : ''}`}>
                    <div className="card-inner">
                        {/* Front side of the card */}
                        <div className="card-front">
                            <div className="imbBx">
                                <img src={imageSrc} alt={name} />
                            </div>
                        </div>
                        {/* Back side of the card */}
                        <div className="card-back">
                            <div className="content-back">
                                <h3 className="card1__name">{name}</h3>
                                <p className="card1__role">{role}</p>
                                <p>{backContent}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeamSection;
